if (!window.__COMMON_TVL__H) {
    window.__COMMON_TVL__H=1;
    require("owl.carousel/dist/assets/owl.carousel.css");
    require("../css/bootstrap.min.css");
    require("owl.carousel/dist/assets/owl.theme.default.css");
    require("../css/app.scss");
    require("../css/app_common.scss");
    
    require("./fontawesome.js");
    require("script-loader!jquery");
    require("script-loader!./bootstrap.min.js");
    require("script-loader!owl.carousel/dist/owl.carousel.min.js");
    require("script-loader!./custom.js");
    
    
}
